import { ConditionalTriggerUtils, EncryptUtils } from '@novisto/common';
import keyBy from 'lodash/keyBy';

import {
  BaseValue,
  BooleanTypeDetails,
  BooleanValue,
  ChoiceTypeDetails,
  ChoiceValue,
  ConditionalTrigger,
  EmbedderValue,
  EmbedderValueField,
  EmbedderValueId,
  IContextSettings,
  Indicator,
  NumberTypeDetails,
  Value,
  ValueDefinitionType,
  ValueGroup,
} from '../models';

export class EmbedderUtils {
  private static readonly SUPPORTED_TYPES = [
    ValueDefinitionType.boolean,
    ValueDefinitionType.calculated,
    ValueDefinitionType.choice,
    ValueDefinitionType.date,
    ValueDefinitionType.number,
    ValueDefinitionType.file,
    ValueDefinitionType.file_v2,
    ValueDefinitionType.text,
  ];

  public static encodeId(id: EmbedderValueId) {
    return EncryptUtils.encoded(id);
  }

  public static formatId(
    settings: IContextSettings,
    embedderValue: EmbedderValue,
    formattedValue: string | string[],
    field: EmbedderValueField = EmbedderValueField.value,
    fieldId?: string,
  ): string {
    const id: EmbedderValueId = {
      embedderValue,
      field,
      fieldId,
      fiscalYear: settings.fiscalYear.id,
      formattedValue,
      indicatorId: embedderValue.indicatorId,
      metricId: embedderValue.metricId,
      sourceId: settings.source.id,
      tableId: embedderValue.table?.[0].table_id,
      valueDefinitionId: embedderValue.value?.value_definition_id,
    };
    return this.encodeId(id);
  }

  public static formatEmbedderValues(indicator: Indicator): EmbedderValue[] {
    let tableId: string | null = null;
    let table: ValueGroup[] = [];
    const indicatorId = indicator.id;
    const metricId = String(indicator.metric_id);
    const values: EmbedderValue[] = [];
    const vgs = indicator.value_group_sets?.[0];
    const allValues = vgs?.value_groups?.flatMap((vg) => vg.values).filter((v): v is Value => Boolean(v)) || [];

    vgs?.value_groups?.forEach((vg) => {
      if (this.checkTriggers(allValues, vg.conditional_triggers)) {
        if (vg.table_id) {
          if (tableId && vg.table_id !== tableId) {
            table = this.appendTable(values, table, metricId, indicatorId);
          }

          tableId = vg.table_id;
          table.push(vg);
        } else {
          const group = vg.repeatable ? vg.values?.filter((v) => this.isSupportedValue(allValues, v)) : undefined;
          tableId = null;
          table = this.appendTable(values, table, metricId, indicatorId);
          vg.values?.forEach((v) => {
            if (this.isSupportedValue(allValues, v)) {
              values.push({ id: this.formatEmbedderValueId(vg, v), group, indicatorId, metricId, value: v });
            }
          });
        }
      }
    });
    table = this.appendTable(values, table, metricId, indicatorId);

    return values;
  }

  public static fetchId(encodedId: string): EmbedderValueId | undefined {
    try {
      return EncryptUtils.decoded(encodedId);
    } catch (_) {
      return undefined;
    }
  }

  public static formatTableEmbedderValue(embedderValue: EmbedderValue, value: Value): EmbedderValue {
    return { ...embedderValue, value };
  }

  public static getEmbedderValueByIndicator(indicators: Indicator[]): Record<string, Record<string, EmbedderValue>> {
    return indicators.reduce((acc: Record<string, Record<string, EmbedderValue>>, indicator) => {
      acc[String(indicator.metric_id)] = keyBy(this.formatEmbedderValues(indicator), 'id');
      return acc;
    }, {});
  }

  public static isWord(): boolean {
    return typeof Word === 'object';
  }

  private static appendTable(
    values: EmbedderValue[],
    table: ValueGroup[],
    metricId: string,
    indicatorId: string,
  ): ValueGroup[] {
    if (table.length) {
      let tableTotals: ValueGroup | undefined;

      if (table[table.length - 1].values?.every((v) => v.type === ValueDefinitionType.calculated)) {
        tableTotals = table.pop();
      }

      values.push({ id: this.formatEmbedderValueId(table[0]), indicatorId, metricId, table, tableTotals });
    }
    return [];
  }

  private static checkTriggers(values: Value[], triggers?: ConditionalTrigger[]): boolean {
    if (!triggers?.length) {
      return true;
    }

    return triggers.some((t) =>
      this.isTriggerTriggered(
        t,
        values.find((v) => v.value_definition_id === t.source_value_definition_id),
      ),
    );
  }

  private static formatEmbedderValueId(vg: ValueGroup, v?: Value): string {
    return vg.table_id || `${vg.value_definition_group_id}-${vg.subposition}-${v?.value_definition_id}`;
  }

  private static isSupportedValue(allValues: Value[], value: Value): boolean {
    const triggered = this.checkTriggers(allValues, value.conditional_triggers);
    return Boolean(this.SUPPORTED_TYPES.includes(value.type) && triggered);
  }

  private static isTriggerTriggered(trigger: ConditionalTrigger, value?: Value): boolean {
    if (value?.value) {
      switch (value.type) {
        case ValueDefinitionType.boolean:
          const booleanValue: boolean = (value as BaseValue<BooleanTypeDetails, BooleanValue>).value?.value as boolean;
          return (trigger.values as boolean[]).includes(booleanValue);

        case ValueDefinitionType.number:
          return ConditionalTriggerUtils.compareValues(
            (value as BaseValue<NumberTypeDetails, string>).value,
            String(trigger.values),
            trigger.operator,
          );

        case ValueDefinitionType.choice:
        default:
          const choiceValues: string[] | undefined = (value as BaseValue<ChoiceTypeDetails, ChoiceValue>).value?.values;
          return (
            Array.isArray(choiceValues) && (trigger.values as string[]).every((value) => choiceValues.includes(value))
          );
      }
    }

    return false;
  }
}
