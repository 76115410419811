import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, filter, tap } from 'rxjs';

import { EmbedderService } from '../embedder/embedder.service';
import { ActionItem, FiscalYear, IContextSettings, Source } from '../../models';

@Injectable({ providedIn: 'root' })
export class ContextSettingsService {
  public static readonly WORD_KEY = 'settings';

  private _settings = new BehaviorSubject<IContextSettings | null>(null);

  public readonly settings$: Observable<IContextSettings>;

  constructor(private readonly embedderService: EmbedderService) {
    this.settings$ = this._settings.asObservable().pipe(filter((s): s is IContextSettings => Boolean(s)));
  }

  public initialize(
    fiscalYears: ActionItem<FiscalYear>[],
    soruces: ActionItem<Source>[],
    loadedSettings: IContextSettings | null,
  ): IContextSettings {
    const settings = loadedSettings || this.createDefaultIContextSettings(fiscalYears, soruces);
    this._settings.next(settings);
    return settings;
  }

  public saveSettings(newSettings: IContextSettings): Observable<void> {
    return this.embedderService.saveSetting(ContextSettingsService.WORD_KEY, newSettings).pipe(
      tap(() => {
        this._settings.next(newSettings);
      }),
    );
  }

  private createDefaultIContextSettings(
    fiscalYears: ActionItem<FiscalYear>[],
    soruces: ActionItem<Source>[],
  ): IContextSettings {
    const currentYear = String(new Date().getFullYear());

    return {
      editableControls: false,
      fiscalYear: fiscalYears.find((f) => f.id === currentYear) || fiscalYears[0],
      highlightControls: false,
      source: soruces.find((s) => s.item?.is_default) || soruces[0],
    };
  }
}
