<mat-toolbar class="page-context-bar" [ngClass]="{ 'pt-2': anchorName }">
  <nav *ngIf="anchorName">
    <a (click)="anchorClick.emit()" class="anchor" role="tab">
      <mat-icon svgIcon="arrow-head-left"></mat-icon>
      {{ anchorName }}
    </a>
  </nav>

  <div class="page-context-bar-content" [ngClass]="{ 'pt-0 pb-2': !title }">
    <div *ngIf="title" class="title-container" [ngClass]="{ separator: withSeparator }">
      <h9 [matTooltip]="title" class="page-name">
        {{ title }}
      </h9>

      <a *ngIf="withSignOut" (click)="authService.logout()" class="anchor">
        <mat-icon class="mat-icon notranslate icon-sm mr-1" [svgIcon]="'arrow-full-right'"></mat-icon>
        {{ "Sign Out" | translate }}
      </a>
    </div>

    <div class="subtitle-container">
      <div *ngIf="subtitle">
        {{ subtitle }}
      </div>

      <div *ngIf="ctaText">
        <a (click)="ctaClick.emit()">
          <mat-icon *ngIf="ctaIcon" class="mat-icon notranslate icon-sm mr-1" [svgIcon]="ctaIcon"></mat-icon>
          {{ ctaText }}
        </a>
      </div>
    </div>

    <ng-container *ngIf="withSettingsContext">
      <div *ngIf="data$ | async as data" class="settings-container">
        <lib-filter
          [buttonProps]="{
            icon: 'calendar',
            title: 'FY{fiscalYear}' | translate: { fiscalYear: data.settings.fiscalYear.title },
          }"
          [optionProps]="{
            options: data.fiscalYears,
            activeOption: data.settings.fiscalYear,
            defaultValue: { id: currentYear, title: currentYear },
            defaultValueTitle: 'Current ({currentYear})' | translate: { currentYear },
            title: 'Fiscal year' | translate,
          }"
          (optionSelected)="onFiscalYearChange(data.settings, $event)"
        ></lib-filter>
        <lib-filter
          [buttonProps]="{ icon: 'location', title: data.settings.source.title }"
          [optionProps]="{
            options: data.sources,
            activeOption: data.settings.source,
            title: 'Sources' | translate,
          }"
          (optionSelected)="onSourceChange(data.settings, $event)"
        ></lib-filter>
      </div>
    </ng-container>
  </div>
</mat-toolbar>
