import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { ActionItemUtils } from '@novisto/common';

import { ActionItem, ApiResponse, ResourceType, Source } from '../../models';
import { PublicApiService } from '../api/public-api.service';

@Injectable({ providedIn: 'root' })
export class PublicSourcesService {
  private readonly resource: string;
  private readonly servicePath: string;
  private _sources$ = new BehaviorSubject<ActionItem<Source>[]>([]);

  public readonly sources$ = this._sources$.asObservable();

  constructor(private readonly apiService: PublicApiService) {
    this.servicePath = apiService.getServicePath();
    this.resource = this.apiService.apiConfig.apis.external.resources.source;
  }

  public initialize(): Observable<ActionItem<Source>[]> {
    return this.apiService
      .get<ApiResponse<Source[]>>(`${this.servicePath}${this.resource}/business_units`, {
        order_by: 'name',
        order_by_direction: 'asc',
      })
      .pipe(
        map((res) => ActionItemUtils.resourcesToActionItem<Source>(res.data, ResourceType.source)),
        tap((sources) => {
          this._sources$.next(sources);
        }),
      );
  }
}
