import { Injectable } from '@angular/core';

import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActionItemUtils } from '@novisto/common';

import { ActionItem, ApiResponse, FiscalYear, ResourceType } from '../../models';
import { PublicApiService } from '../api/public-api.service';

@Injectable({ providedIn: 'root' })
export class PublicFiscalYearsService {
  private readonly resource: string;
  private readonly servicePath: string;
  private _fiscalYears$ = new BehaviorSubject<ActionItem<FiscalYear>[]>([]);

  public readonly fiscalYears$ = this._fiscalYears$.asObservable();

  constructor(private apiService: PublicApiService) {
    this.servicePath = apiService.getServicePath();
    this.resource = this.apiService.apiConfig.apis.external.resources.fiscalYears;
  }

  public initialize(): Observable<ActionItem<FiscalYear>[]> {
    return this.apiService
      .get<ApiResponse<FiscalYear[]>>(`${this.servicePath}${this.resource}/fiscal_years`, {
        params: { limit: 0, offset: 0, order_by: 'start', order_by_direction: 'desc' },
      })
      .pipe(
        map((res) => {
          const fiscalYears = res.data.map((fy) => ({ ...fy, year: Number(fy.frequency_code) }));
          return ActionItemUtils.resourcesToActionItem<FiscalYear>(fiscalYears, ResourceType.year);
        }),
        tap((fiscalYears) => {
          this._fiscalYears$.next(fiscalYears);
        }),
      );
  }
}
