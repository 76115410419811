import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NovistoCommonModule, MaterialModule } from '@novisto/common';
import { TranslateModule } from '@ngx-translate/core';

import { AlertComponent } from './alert/alert.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { PageContextBarComponent } from './page-context-bar/page-context-bar.component';

@NgModule({
  declarations: [AlertComponent, ConfirmationDialogComponent, PageContextBarComponent],
  imports: [MaterialModule, RouterModule, CommonModule, NovistoCommonModule, TranslateModule],
  exports: [AlertComponent, ConfirmationDialogComponent, PageContextBarComponent],
})
export class ComponentsModule {}
