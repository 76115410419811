<div class="fx-row justify-space-between align-center m-2">
  <h3 class="m-0">{{ data.title }}</h3>
  <button lib-button (click)="cancel()">
    <mat-icon class="icon" [svgIcon]="'close'"></mat-icon>
  </button>
</div>

<lib-divider></lib-divider>

<div class="m-2">
  <p>{{ data.primaryMsg }}</p>
  <p *ngIf="data.secondaryMsg">{{ data.secondaryMsg }}</p>
</div>

<lib-divider></lib-divider>

<div class="fx-row justify-start align-center m-2">
  <button lib-button buttonType="primary" (click)="confirm()">
    {{ data.primaryBtn }}
  </button>
  <button *ngIf="data.secondaryBtn" lib-button buttonType="cancel_with_border" class="ml-2" (click)="cancel()">
    {{ data.secondaryBtn }}
  </button>
</div>
