import { FormatNumberBrowserLocalePipe, LoaderStateService, TranslateService, UnitSymbolPipe } from '@novisto/common';
import { Observable, concatMap, finalize, forkJoin, from, map, of, switchMap, toArray, withLatestFrom } from 'rxjs';

import {
  CheckPublicIndicatorValuesUpdated,
  CheckPublicIndicatorValuesUpdatedPayload,
  EmbedderHighlightColors,
  EmbedderInsertOptions,
  EmbedderValue,
  EmbedderValueField,
  EmbedderValueId,
  IContextSettings,
  Indicator,
  MinimalDocumentMetaData,
  UpdateEmbedderValuesOptions,
  Value,
  ValueDefinitionType,
  ValueGroup,
} from '../../models';
import { EmbedderUtils } from '../../utilities/embedder-utils';
import { PublicDocumentsService } from '../public-documents/public-documents.service';
import { PublicIndicatorsService } from '../public-indicators/public-indicators.service';
import groupBy from 'lodash/groupBy';
import { PublicFiscalYearsService } from '../public-fiscal-years/public-fiscal-years.service';
import { PublicSourcesService } from '../public-source/public-source.service';
import { ValueUtils } from '../../utilities/value-utils';

export abstract class EmbedderService {
  protected editableControls = false;
  protected highlightControls = false;

  constructor(
    protected readonly formatNumberBrowserLocalePipe: FormatNumberBrowserLocalePipe,
    protected readonly loaderStateService: LoaderStateService,
    protected readonly publicDocumentsService: PublicDocumentsService,
    protected readonly publicFiscalYearsService: PublicFiscalYearsService,
    protected readonly publicIndicatorsService: PublicIndicatorsService,
    protected readonly publicSourcesService: PublicSourcesService,
    protected readonly translateService: TranslateService,
    protected readonly unitSymbolPipe: UnitSymbolPipe,
  ) {}

  public checkForUpdates(): Observable<CheckPublicIndicatorValuesUpdated[]> {
    return this.getEmbedderValueIds({ updatedValues: true }).pipe(
      map((embbederValueIds) => {
        const previousValues: CheckPublicIndicatorValuesUpdatedPayload['previous_values'] = [];
        embbederValueIds.forEach(({ embedderValue }) => {
          if (embedderValue.table) {
            embedderValue.table.forEach((vg) => {
              vg.values?.forEach((v) => {
                if (v.id) {
                  previousValues.push({ value: v.value, value_id: v.id });
                }
              });
            });
          } else if (embedderValue.value?.id) {
            previousValues.push({ value: embedderValue.value.value, value_id: embedderValue.value.id });
          }
        });
        return previousValues;
      }),
      switchMap((previousValues) =>
        previousValues.length
          ? this.publicIndicatorsService
              .checkValuesUpdated({ previous_values: previousValues })
              .pipe(map((res) => res.data))
          : of([]),
      ),
    );
  }

  public getContextSettings(
    context: OfficeExtension.ClientRequestContext,
  ): Excel.SettingCollection | Word.SettingCollection | undefined {
    if (EmbedderUtils.isWord() && context instanceof Word.RequestContext) {
      return context.document.settings;
    } else if (context instanceof Excel.RequestContext) {
      return context.workbook.settings;
    }

    return;
  }

  public getSetting<T>(key: string): Observable<T | null> {
    return this.run(async (context: OfficeExtension.ClientRequestContext) => {
      const settings = this.getContextSettings(context);
      const setting = settings?.getItemOrNullObject(key);
      setting?.load();
      await context.sync();

      return !setting || setting.isNullObject ? null : JSON.parse(String(setting.value));
    });
  }

  public isWord(): boolean {
    return EmbedderUtils.isWord();
  }

  public initialize(settings: IContextSettings): void {
    this.editableControls = Boolean(settings.editableControls);
    this.highlightControls = Boolean(settings.highlightControls);
  }

  public saveSetting(key: string, newSetting: unknown): Observable<void> {
    return this.run(async (context: OfficeExtension.ClientRequestContext) => {
      const settings = this.getContextSettings(context);
      settings?.add(key, JSON.stringify(newSetting));
      return context.sync();
    });
  }

  public updateExistingValues(
    settings: IContextSettings,
    changes: CheckPublicIndicatorValuesUpdated[],
  ): Observable<boolean> {
    this.loaderStateService.openloader(this.translateService.instant('Updating values'));

    return this.getEmbedderValueIds({ changes }).pipe(
      switchMap((embedderValueIds) => this.updateValues(settings, embedderValueIds)),
      finalize(() => this.loaderStateService.closeloader()),
    );
  }

  public updateValuesContext(
    settings: IContextSettings,
    fiscalYearContexts: [Partial<IContextSettings>, Partial<IContextSettings>][],
    sourceContexts: [Partial<IContextSettings>, Partial<IContextSettings>][],
  ): Observable<boolean> {
    this.loaderStateService.openloader(this.translateService.instant('Updating values'));

    return from([fiscalYearContexts, sourceContexts]).pipe(
      concatMap((contexts) => this.updateContexts(settings, contexts)),
      toArray(),
      map((results) => results.includes(true)),
      finalize(() => this.loaderStateService.closeloader()),
    );
  }

  protected filterEmbedderValueIds(
    embbededValueIds: EmbedderValueId[],
    options?: UpdateEmbedderValuesOptions,
  ): EmbedderValueId[] {
    let ids: EmbedderValueId[] = embbededValueIds;

    if (options?.changes) {
      const changes = options.changes;
      ids = embbededValueIds.filter((embbededValueId) => {
        if (embbededValueId.embedderValue.value) {
          const change = changes.find((c) => c.value_id === embbededValueId.embedderValue.value?.id);
          return !change || change.has_changed;
        } else if (embbededValueId.embedderValue.table) {
          const tableIds = embbededValueId.embedderValue.table.flatMap((vg) => vg.values?.map((v) => v.id) || []);
          const tableChanges = changes.filter((c) => tableIds.includes(c.value_id));
          return !tableChanges.length || tableChanges.some((c) => c.has_changed);
        }

        return false;
      });
    } else if (options?.context?.fiscalYear) {
      const fiscalYear = options.context.fiscalYear;
      ids = embbededValueIds.filter((embbededValueId) => embbededValueId.fiscalYear === fiscalYear.id);
    } else if (options?.context?.source) {
      const source = options.context.source;
      ids = embbededValueIds.filter((embbededValueId) => embbededValueId.sourceId === source.id);
    }

    return ids;
  }

  protected getTableValues(
    settings: IContextSettings,
    embedderValue: EmbedderValue,
    table: ValueGroup[],
  ): { ids: Record<string, string>; values: string[] } {
    const cols = Number(table[0].values?.length);
    const embedderValueIds: Record<string, string> = {};
    const values = table.flatMap((vg) => vg.values || []);
    const headers = table[0].values?.map((v) =>
      v.type === ValueDefinitionType.label ? String(v.label) : this.getTableRowInputLabel(v, false),
    );
    const tableValues: string[] = headers || [];

    for (let i = 0; i < values.length; i++) {
      const value = values[i];

      if (value.type === ValueDefinitionType.label) {
        tableValues.push(String(value.type_details.value));
      } else {
        const row = Math.floor(i / cols) + 1;
        const col = i % cols;

        const fieldEmbedderValue = EmbedderUtils.formatTableEmbedderValue(embedderValue, value);
        const formattedValue = this.formatValue(fieldEmbedderValue, EmbedderValueField.value);
        const id = EmbedderUtils.formatId(settings, fieldEmbedderValue, formattedValue.value, EmbedderValueField.value);
        embedderValueIds[`${row}-${col}`] = id;
        tableValues.push(String(formattedValue.value));
      }
    }

    return { ids: embedderValueIds, values: tableValues };
  }

  public abstract embbedFileV2(
    settings: IContextSettings,
    embedderValue: EmbedderValue,
    documents: Record<string, MinimalDocumentMetaData>,
  ): Observable<void>;

  public abstract embbedTableAsList(settings: IContextSettings, embedderValue: EmbedderValue): Observable<void>;

  public abstract embbedTableAsTable(settings: IContextSettings, embedderValue: EmbedderValue): Observable<void>;

  public abstract embbedValue(
    settings: IContextSettings,
    documents: Record<string, MinimalDocumentMetaData>,
    embedderValue: EmbedderValue,
    field: EmbedderValueField,
    fieldId?: string,
    options?: EmbedderInsertOptions,
  ): Observable<void>;

  public abstract getEmbedderValueIds(options?: UpdateEmbedderValuesOptions): Observable<EmbedderValueId[]>;

  public abstract getSelectedEmbedderValues(): Observable<EmbedderValueId[]>;

  public abstract setControlsFormat(settings?: IContextSettings): Observable<void>;

  protected abstract run<T>(execute: (context: OfficeExtension.ClientRequestContext) => Promise<T>): Observable<T>;

  protected abstract highlightControl(
    controlId: string,
    highlightColor: EmbedderHighlightColors | null,
  ): Observable<void>;

  protected formatValue(
    embedderValue: EmbedderValue,
    field: EmbedderValueField,
    fieldId?: string,
    documents: Record<string, MinimalDocumentMetaData> = {},
  ): { value: string | string[]; html: boolean } {
    return ValueUtils.formatValue(this.formatNumberBrowserLocalePipe, embedderValue, field, fieldId, documents);
  }

  protected getTableRowInputLabel(value: Value, withColon = true): string {
    let label = value.label || '';

    if (value.type_details.units && value.type_details.units !== 'default') {
      label += ` ${this.unitSymbolPipe.transform(String(value.type_details.units || ''), true)}`;
    }

    if (withColon) {
      label += ': ';
    }

    return label;
  }

  private fetchIndicators(
    settings: IContextSettings,
    metricIds: string[],
  ): Observable<[Indicator[], Record<string, MinimalDocumentMetaData>]> {
    return this.publicIndicatorsService
      .search(
        {
          business_unit_id: settings.source.id,
          fiscal_year: settings.fiscalYear.id,
          filters: { metric_ids: metricIds },
        },
        true,
      )
      .pipe(switchMap((res) => forkJoin([of(res.data), this.publicDocumentsService.getIndicatorDocuments(res.data)])));
  }

  private updateContexts(
    settings: IContextSettings,
    contexts: [Partial<IContextSettings>, Partial<IContextSettings>][],
  ): Observable<boolean> {
    return from(contexts).pipe(
      concatMap(([fromContext, toContext]) =>
        forkJoin([this.getEmbedderValueIds({ context: fromContext }), of(fromContext), of(toContext)]),
      ),
      concatMap(([embedderValueIds, fromContext, toContext]) =>
        this.updateValues(settings, embedderValueIds, toContext, fromContext.fiscalYear ? 'sourceId' : 'fiscalYear'),
      ),
      toArray(),
      map((results) => results.includes(true)),
    );
  }

  private updateEmbedderValues(settings: IContextSettings, embedderValueIds: EmbedderValueId[]): Observable<boolean> {
    if (!embedderValueIds.length) {
      return of(false);
    }

    return this.fetchIndicators(
      settings,
      embedderValueIds.map((e) => e.metricId),
    ).pipe(
      switchMap(([indicators, documents]) => {
        const valuesByIndicator = EmbedderUtils.getEmbedderValueByIndicator(indicators);
        return from(
          embedderValueIds.map((embbederValueId) => ({
            embbederValueId,
            values: valuesByIndicator[embbederValueId.metricId] || {},
            documents,
          })),
        );
      }),
      concatMap(({ embbederValueId, values, documents }) => {
        const oldId = EmbedderUtils.encodeId(embbederValueId);
        let newEmbedderValue = values[embbederValueId.embedderValue.id];

        if (newEmbedderValue) {
          if (embbederValueId.embedderValue.value && embbederValueId.embedderValue.table) {
            const values = newEmbedderValue.table?.flatMap((vg) => vg.values) || [];
            const val = values.find((v) => v?.value_definition_id === embbederValueId.valueDefinitionId);
            newEmbedderValue = val ? EmbedderUtils.formatTableEmbedderValue(newEmbedderValue, val) : newEmbedderValue;
          }

          return this.embbedValue(
            settings,
            documents,
            newEmbedderValue,
            embbederValueId.field,
            embbederValueId.fieldId,
            { controlId: oldId, highlightColor: EmbedderHighlightColors.updated },
          ).pipe(map(() => false));
        }

        return this.highlightControl(oldId, EmbedderHighlightColors.deleted).pipe(map(() => true));
      }),
      toArray(),
      map((results) => results.includes(true)),
    );
  }

  private updateValues(
    settings: IContextSettings,
    embedderValueIds: EmbedderValueId[],
    toContext: Partial<IContextSettings> = {},
    key?: string,
  ): Observable<boolean> {
    return from(Object.entries(groupBy(embedderValueIds, key ? key : (id) => `${id.fiscalYear}*${id.sourceId}`))).pipe(
      withLatestFrom(this.publicFiscalYearsService.fiscalYears$, this.publicSourcesService.sources$),
      concatMap(([[key, embedderValueIds], fiscalYears, sources]) => {
        const keys = key.split('*');
        const fiskalYearKey = keys[0];
        const sourceKey = keys[1] || keys[0];
        const fiscalYear = fiscalYears.find((fy) => fy.id === fiskalYearKey) || settings.fiscalYear;
        const source = sources.find((s) => s.id === sourceKey) || settings.source;

        return this.updateEmbedderValues({ ...settings, fiscalYear, source, ...toContext }, embedderValueIds);
      }),
      toArray(),
      map((results) => results.includes(true)),
    );
  }
}
