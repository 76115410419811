import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AuthService } from '../../services/auth/auth.service';
import { ContextSettingsService } from '../../services/context-settings/context-settings.service';
import { ActionItem, FiscalYear, IContextSettings, Source } from '../../models';
import { Observable, map, withLatestFrom } from 'rxjs';
import { PublicFiscalYearsService } from '../../services/public-fiscal-years/public-fiscal-years.service';
import { PublicSourcesService } from '../../services/public-source/public-source.service';

type Data = {
  fiscalYears: ActionItem<FiscalYear>[];
  settings: IContextSettings;
  sources: ActionItem<Source>[];
};

@Component({
  selector: 'addon-page-context-bar',
  templateUrl: './page-context-bar.component.html',
  styleUrls: ['./page-context-bar.component.scss'],
})
export class PageContextBarComponent implements OnInit {
  @Input() anchorName: string = '';
  @Input() ctaIcon: string = '';
  @Input() ctaText: string = '';
  @Input() subtitle: string = '';
  @Input() title: string = '';
  @Input() withSeparator: boolean = false;
  @Input() withSettingsContext: boolean = false;
  @Input() withSignOut: boolean = false;

  @Output() anchorClick = new EventEmitter<void>();
  @Output() ctaClick = new EventEmitter<void>();

  public currentYear = String(new Date().getFullYear());
  public data$?: Observable<Data>;

  constructor(
    public readonly authService: AuthService,
    private readonly contextSettingsService: ContextSettingsService,
    private readonly publicFiscalYearsService: PublicFiscalYearsService,
    private readonly publicSourcesService: PublicSourcesService,
  ) {}

  public ngOnInit(): void {
    this.data$ = this.contextSettingsService.settings$.pipe(
      withLatestFrom(this.publicFiscalYearsService.fiscalYears$, this.publicSourcesService.sources$),
      map(([settings, fiscalYears, sources]) => ({
        fiscalYears,
        settings,
        sources,
      })),
    );
  }

  public onFiscalYearChange(settings: IContextSettings, fiscalYear: ActionItem<FiscalYear>): void {
    this.contextSettingsService.saveSettings({ ...settings, fiscalYear }).subscribe();
  }

  public onSourceChange(settings: IContextSettings, source: ActionItem<Source>): void {
    this.contextSettingsService.saveSettings({ ...settings, source }).subscribe();
  }
}
